import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import connectComponent from "../../redux/connectComponent";
import Container from "components/container";
import { Typography } from "@material-ui/core";
import Zip from "./zip";
import Projects from "./projects";

export const hasGeo = () => navigator && navigator.geolocation;

const Browse = ({ actions, match, database, history }) => {
  const routePostalCode = match.params.id;
  const [fullZip, setFullzip] = useState(false);

  // URL-provided takes precedence
  const currentZip = database.authentication.currentZip() || undefined;

  // watch for url-provided zip and set flag accordingly
  useEffect(() => {
    if (currentZip && currentZip.length == 5) {
      setFullzip(true);
    }
  }, [currentZip]);

  useEffect(() => {
    if (!routePostalCode && !currentZip && hasGeo()) {
      navigator.geolocation.getCurrentPosition(
        (position) =>
          position &&
          actions.loadCurrentZip([
            position.coords.latitude,
            position.coords.longitude
          ]),
        (err) => {
          console.log("Location Error", err);
        }
      );
    }
  }, []);

  // On change of zip, redirect
  const handleZip = (zip) => history.push(`/browse/${zip}`);

  // set flag depending on zip length
  const verifyZip = (zip) => {
    if (zip.length == 5) {
      setFullzip(true);
      handleZip(zip);
    } else {
      setFullzip(false);
    }
  };

  // on new url-provided zip that originated from outside store
  if (routePostalCode && !currentZip) {
    actions.setCurrentZip(routePostalCode);
  }

  return (
    <Container style={{ maxWidth: 850 }}>
      <div className="row mt-4">
        <div className="col-8">
          <Typography variant="h6" className="pt-4">
            Restaurants coming to you {currentZip ? ` in ${currentZip}` : ""}
          </Typography>
        </div>
        <div className="col-4">
          <Zip onChange={verifyZip} />
        </div>
      </div>
      <div className="container" style={{ maxWidth: 850 }}>
        <div
          className="row row-eq-height row-pad-col-bottoms"
          style={{ justifyContent: "center" }}
        >
          {fullZip ? (
            <Projects postalCode={currentZip} />
          ) : (
            <div>
              <Typography variant="h6" className="pt-4">
                Please enter a valid zip code
              </Typography>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

Browse.propTypes = {
  actions: PropTypes.object,
  match: PropTypes.object,
  database: PropTypes.object,
  history: PropTypes.object
};

export default connectComponent(Browse, ["authentication"]);
